
/*
// import ability from './ability';

// export const canNavigate = (to) => {
//      to.matched.some(route => {
//         return ability.can(route.meta.action || 'show', route.meta.resource);
//         }
//     );
// }


import ability from './ability';

export const canNavigate = (to) => {

    console.log("ability");
    console.log(ability);
    console.log("to");
    console.log(to);
    to.matched.some(route => {
        console.log(ability.can(route.meta.action || 'show', route.meta.resource));

    })

    //return false;
//   return to.matched.some(route => {
//     return ability.can(route.meta.action || 'show', route.meta.resource);
//   });
}


*/




import ability from './ability';

export const canNavigate = (to) => {
  return to.matched.every(route => {
    const canAccess = ability.can(route.meta.action || 'show', route.meta.resource);
    console.log(`Route: ${route.meta.resource}, Action: ${route.meta.action}, Can Access: ${canAccess}`);
    return canAccess;
  });
}