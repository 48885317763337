<template>
    <v-card>
      <h1>Administrador</h1>

      {{ ability.can('read', 'Post') }}
    
  <button type="button" @click="salir">Salir</button>

  <div v-if="ability.can('show', 'user')">
    <h1>si puede</h1>
  </div>
  <div v-else>
    <h1>No tienes permiso para ver esta página</h1>
  </div>


</v-card>
</template>

<script setup>
import ability from "./../../casl/ability"
</script>


<script>
//import { useRouter } from "vue-router";

//const router = useRouter()

export default {
  methods: {

    async salir(){
      localStorage.removeItem("token");
      //router.push({name: 'Login'})
      this.$router.push({ name: "Login" });
    }

  }
}
</script>