<script setup>
//import { computed, watch, ref } from 'vue';
//import authService from "./../services/auth.service";
//import authService from "./../service/AuthService" Se deja de usar porque no servía esta función. Tiraba error.
import Vuetify from 'vuetify';
import { abilitiesPlugin } from '@casl/vue';
</script>

<script>

export default {
  name: 'App',

  vuetify: new Vuetify(),
  data () {
    return {

      directives: {
        can: abilitiesPlugin.directive,
      },

      drawer: false,
      mini: false,

      showTipoSubMenu: false,

      items: [
        { title: 'Home', icon: 'mdi-account', link:'/admin/home' },
        // { title: 'About', icon: 'mdi-account', link:'/admin/about' },
        { title: 'Fichas', icon: 'mdi-file-outline', link:'/admin/fichas' },
        { title: 'Agenda', icon: 'mdi-file-outline', link:'/admin/seguimiento' },
        { title: 'Alertas', icon: 'mdi-file-outline', link:'/admin/alerta' },
        { title: 'Siniestro', icon: 'mdi-file-outline', link:'/admin/siniestro' },

        // { title: 'Tipo Siniestro', icon: 'mdi-file-outline', link:'/admin/tipoSiniestro' },
        // { title: 'Tipo Cobertura', icon: 'mdi-file-outline', link:'/admin/tipoCobertura' },
        // { title: 'Tipo Riesgo', icon: 'mdi-file-outline', link:'/admin/tipoRiesgo' },
        
        //{ title: 'Permisos', icon: 'mdi-cash', link:'/admin/permiso' },
        //{ title: 'Roles', icon: 'mdi-cash', link:'/admin/role' },
        //{ title: 'Usuarios', icon: 'mdi-cash', link:'/admin/usuario' },
        //{ title: 'login', icon: 'mdi-account-group-outline', link:'/' },
        //{ title: 'logout', icon: 'mdi-logout', action: "onSalir" },
      ],

      tipoItems: [
        { title: 'Tipo Cobertura', icon: 'mdi-file-outline', link: '/admin/tipoCobertura' },
        { title: 'Tipo Riesgo', icon: 'mdi-file-outline', link: '/admin/tipoRiesgo' },
        { title: 'Tipo Siniestro', icon: 'mdi-file-outline', link: '/admin/tipoSiniestro' },
        { title: 'Cliente', icon: 'mdi-file-outline', link:'/admin/cliente' },
        { title: 'Poliza', icon: 'mdi-file-outline', link:'/admin/poliza' },
        { title: 'Categoría Comentario', icon: 'mdi-file-outline', link:'/admin/categoriacomentario' },
        { title: 'Compania', icon: 'mdi-file-outline', link:'/admin/compania' },
        { title: 'Estudio', icon: 'mdi-file-outline', link:'/admin/estudio' },
        { title: 'Analista', icon: 'mdi-file-outline', link:'/admin/gestionador' },
        { title: 'Taller', icon: 'mdi-file-outline', link:'/admin/taller' },
      ]

    }
  },
  
  computed: {
        // Esta función computada proporcionará el método `can` en tu plantilla
        can() {
        return this.$can;
        },
    },

  methods: {

    //toggleDrawer() {
    //  this.$refs.drawer.toggle();
    //},

    toggleTipoSubMenu() {
      this.showTipoSubMenu = !this.showTipoSubMenu;
    },

    handleNavIconClick() {
      this.toggleDrawer();
      this.toggleMini();
    },

    toggleMini() {
      this.mini = !this.drawer;
    },

    toggleDrawer() {
      this.drawer = !this.drawer;
    },

    // Esto es para que si ya se encuentra en Home, no trate de entrar en Home, o la opción que sea.
    handleItemClick(item) {
      if (item.action && typeof this[item.action] === 'function') {
        this[item.action]();
      } else if (item.link !== this.$route.fullPath) {
        this.$router.push(item.link);
      }
    },

    onSalir: async function () {
        try {

            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("permisos");

            this.$router.push({ name: "Login" });

        } catch (error) {
            alert("Ocurrio un problema al salir")
        }
    },

    // Esta versión no anda
    // onSalir: async function () {
    //     try {
    //         await authService.salir();
    //         localStorage.removeItem("access_token");
    //         //router.push('/admin/home');
    //         this.$router.push({ path: '/login' });
    //     } catch (error) {
    //         alert("Ocurrio un problema al salir")
    //     }
    // },
  }
  
};
</script>


<template>

  <v-app style="margin-left: 1%;">

    <v-container>


      <v-app-bar style="margin-bottom: 1%;">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
      <!--v-app-bar-nav-icon @click.stop="toggleDrawer" @click.stop="mini = !mini"></v-app-bar-nav-icon-->
      <v-app-bar-nav-icon @click.stop="handleNavIconClick" class="mr-auto"></v-app-bar-nav-icon>

      <v-img style="max-width:3em"
          src="/../../aicardi.ico"
          alt="Descripción de la imagen"
          width="32"
          height="32"
      ></v-img>

      <v-spacer/>
      <v-toolbar-title class="align-center">Sistema</v-toolbar-title>
      <v-spacer/>

      <!-- <v-icon @click="changeLanguage()" class="mdi mdi-earth" color="primary"></v-icon> -->

      

      <v-btn icon @click="onSalir()">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <!-- <v-menu bottom class="ml-auto">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

      </v-menu> -->
    </v-app-bar>


      <!-- <v-container id="entireView" class="align-start"> -->
      <v-container id="entireView" class="align-start d-flex">

      <!-- v-container id="entireView" class="d-flex flex-row" -->

        <v-navigation-drawer id="drawer" v-model="drawer" :mini-variant.sync="mini" style="position: relative ;" bottom>
          
        <!-- <v-navigation-drawer ref="drawer" :mini-variant.sync="mini" permanent> -->

          <v-list-item >
            <!-- <v-btn icon @click.stop="mini = !mini">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn> -->
            <v-list-item-title>
              Bienvenido
            </v-list-item-title>
          </v-list-item>
    
          <v-divider></v-divider>
    
          <v-list dense>
            <v-list-item 
              v-for="item in items"
              :key="item.title"
              @click="handleItemClick(item)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
    
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.76rem">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>












    <!-- Elemento de menú principal "Tipos" -->
    <!-- <v-list-item @click="toggleTipoSubMenu">
      <v-list-item-icon>
        <v-icon>mdi-file-outline</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Tipos</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon>{{ showTipoSubMenu ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-list-item-action>
    </v-list-item> -->

    <!-- Submenú bajo "Tipos" -->
    <v-list-group v-model="showTipoSubMenu">
      <template v-slot:activator>
        <v-icon>mdi-menu</v-icon>
        <v-list-item-content>
          <v-list-item-title style="font-size: 0.76rem; ">Tablas</v-list-item-title>
        </v-list-item-content>
      </template>

      <!-- Subelementos del submenú -->
      <v-list-item v-for="subitem in tipoItems" :key="subitem.title" @click="handleItemClick(subitem)">
        <v-list-item-icon>
          <v-icon>mdi-table</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-size: 0.76rem; text-align: left;">{{ subitem.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>

























            <v-list-item v-if="can('manage', 'all')" link to="/admin/carga">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.76rem">Carga Excel</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="can('manage', 'all')" link to="/admin/permiso">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.76rem">Permisos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="can('manage', 'all')" link to="/admin/role">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.76rem">Roles</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="can('manage', 'all')" link to="/admin/usuario">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="font-size: 0.76rem">Usuarios</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-list>

          
          
        </v-navigation-drawer>

        <!--v-card id="cardView" fluid class="align-start px-0 d-flex flex-row" style="margin-left: 1%;"-->
        <!--v-card id="cardView" flat class="grow" style="margin-left: 1%;"--> 
        <!--v-card id="entireView" :class="{ 'full-width-card': !drawer }" fluid-->
        <!--v-card id="entireView" fluid class="d-flex flex-row"-->
        <v-card-text id="cardView" style="margin-left: 1%;">
        <!-- <v-card-text id="cardView" :style="{ 'z-index': mini ? '1' : 'auto', 'position': mini ? 'absolute' : 'relative' }" style="margin-left: 1%;"> -->

        <!-- <v-card id="cardView" :style="{ 'z-index': mini ? '1' : '2' }"> -->
              <router-view></router-view>
        </v-card-text>

      </v-container>


    </v-container>
   </v-app>

</template>

<style scoped>



/*

.v-card {
    max-width: 50%;
}



v-navigation-drawer v-navigation-drawer--is-mouseover v-navigation-drawer--open theme--light{
  height: 100%!important; 
  top: 0px!important; 
  transform: translateX(0%)!important; 
  width: 556px!important;
}

v-navigation-drawer v-navigation-drawer--is-mouseover v-navigation-drawer--mini-variant v-navigation-drawer--open theme--light{
  height: 100%!important; 
  top: 0px!important; 
  transform: translateX(0%)!important; 
  width: 556px!important;
}
*/

/* .v-navigation-drawer {
    overflow: unset;
} */

/* Esto es por ejemplo para que aparezca la barra de desplazamiento, para cuando el menu es muy largo y necesito ir y */
.v-navigation-drawer {
    overflow-y: auto;
}

.theme--light.v-card {
  background-color: #f1f5f9;
}

body {
  width: 100%;
  /*max-width: 1200px;  Ancho máximo deseado */
  margin: 0 auto; /* Centrar el contenido */
}

.v-navigation-drawer--close {
    /*visibility: hidden;*/
    width: 0px!important;
    height: 0px!important;

}

/* Ajustes para dispositivos móviles */
@media only screen and (max-width: 600px) {


  /*#cardView{
    z-index: 1000;
  }*/
  .v-navigation-drawer{
    max-width: 25%;
    z-index: 1000;
  }

  .v-navigation-drawer--close {
    visibility: hidden;
    width: 0px!important;
    height: 0px!important;
  }

  .v-application .d-flex {
      display: table-cell!important;
  }

/* Tamaño de Font del menú */
  .v-list-item__title{
  font-size: 0.75rem !important;
}

.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 0.75rem !important;
}

.v-list-item__subtitle {
  font-size: 0.75rem !important;
}

/* *** */

  /*
  v-navigation-drawer v-navigation-drawer--is-mouseover v-navigation-drawer--open theme--light{
    max-width: 5%; 
  }
  .v-navigation-drawer .v-navigation-drawer--is-mouseover .v-navigation-drawer--open .theme--light{
    max-width: 25%; 
  }


  #entireView {
    max-width: 95%; 
  }
  #drawer {
    max-width: 45%; 
  }
  #cardView {
    max-width: 65%; 
  } 
  */

}
</style>
