import axios from "axios"

//export const urlBase = "http://localhost:8000/api";
export const urlBase = "https://aicardi.sistemic.com.ar/back/public/api" // Este es


const App = () => {
    let token = localStorage.getItem("token");

    const api = axios.create({
        baseURL: urlBase,
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer '+token
        },
        timeout: 30000
    });

    // error
    api.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if(error.response.status === 401){
                console.log("INTERCEPTOR:  401 *******")
                localStorage.removeItem("token")
                window.location.href = "/"
            }
            if(error.response.status === 403){
                console.log("INTERCEPTOR:  403 ******* Llega a error")
                window.location.href = "/no-autorizado"
            }

            return Promise.reject(error)
        }
    )
    return api;
}

export default App;