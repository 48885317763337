export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const redireccionPorRol = role => {
    if(role == 'super-admin') return {name: 'admin'}
    if(role == 'cajero') return {name: 'perfil'}
    if(role == 'gerente') return {name: 'perfil'}
    if(role == 'ventas') return {name: 'perfil'}
    if(role == 'usuario') return {path: 'admin/perfil'}
    return {name: 'perfil'}
    
}
