



import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/auth/LoginView.vue'
import Admin from '../views/admin/AdminView.vue'
import Perfil from '../views/admin/PerfilView.vue'
import AppLayout from '../views/AppLayout.vue'
//import Inicio from "../views/InicioView.vue"
import HomeView from '../views/HomeView.vue'
//import CategoriaComentarioView from '../views/sistema/CategoriaComentarioView.vue'

import { canNavigate } from '../casl/routeProtection'
import { getUserData, redireccionPorRol } from '../casl/utils'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'inicio',
    component: Login,
    meta: {
      resource: 'auth',
      action: 'show'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      redirectIfAuth: true,
      resource: 'auth',
      action: 'show'
    }
  },
  {
    path: '/admin',
    component: AppLayout,
    meta: { 
      requireAuth: true,
      resource: 'auth',
      action: 'show'
    },
    children: [
      {
        path: 'about',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
        meta: {requireAuth: true,
          resource: 'auth',
          action: 'show'
        }
      },
      {
        path: 'home',
        name: 'home',
        component: HomeView,
        meta: {
          requireAuth: true,
          resource: 'auth',
          action: 'show'
        }
      },
      {
        path: '/admin',
        name: 'admin',
        component: Admin,
        meta: {
          requireAuth: true,
          resource: 'admin',
          action: 'index'
        }
      },
      {
        path: 'perfil',
        name: 'perfil',
        component: Perfil,
        meta: {
          requireAuth: true,
          resource: 'auth',
          action: 'show'
        },
      },
      {
        path: 'permiso',
        name: 'permiso',
        component: () => import('../views/admin/role/PermisoView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'permiso',
          action: 'index'
        },
      },
      {
        path: 'usuario',
        name: 'usuario',
        component: () => import('../views/admin/usuario/UsuarioView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'user',
          action: 'index'
        },
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('../views/admin/role/RoleView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'role',
          action: 'index'
        },
      },
      {
        path: 'carga',
        name: 'carga',
        component: () => import('../views/admin/carga/CargaView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'carga',
          action: 'index'
        },
      },
      {
        path: 'categoriaComentario',
        name: 'categoriaComentario',
        component: () => import('../views/sistema/CategoriaComentarioView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'categoriaComentario',
          action: 'index'
        },
      },
      {
        path: 'cliente',
        name: 'cliente',
        component: () => import('../views/sistema/ClienteView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'cliente',
          action: 'index'
        },
      },
      {
        path: 'compania',
        name: 'compania',
        component: () => import('../views/sistema/CompaniaView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'compania',
          action: 'index'
        },
      },
      {
        path: 'estudio',
        name: 'estudio',
        component: () => import('../views/sistema/EstudioView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'estudio',
          action: 'index'
        },
      },
      {
        path: 'gestionador',
        name: 'gestionador',
        component: () => import('../views/sistema/GestionadorView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'gestionador',
          action: 'index'
        },
      },
      {
        path: 'taller',
        name: 'taller',
        component: () => import('../views/sistema/TallerView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'taller',
          action: 'index'
        },
      },
      {
        path: 'tipoSiniestro',
        name: 'tipoSiniestro',
        component: () => import('../views/sistema/TipoSiniestroView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'tipoSiniestro',
          action: 'index'
        },
      },
      {
        path: 'tipoCobertura',
        name: 'tipoCobertura',
        component: () => import('../views/sistema/TipoCoberturaView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'tipoCobertura',
          action: 'index'
        },
      },
      {
        path: 'tipoRiesgo',
        name: 'tipoRiesgo',
        component: () => import('../views/sistema/TipoRiesgoView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'tipoRiesgo',
          action: 'index'
        },
      },
      {
        path: 'poliza',
        name: 'poliza',
        component: () => import('../views/sistema/PolizaView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'poliza',
          action: 'index'
        },
      },
      {
        path: 'fichas',
        name: 'fichas',
        component: () => import('../views/sistema/FichaGrillaView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'seguimiento',
          action: 'index'
        },
      },
      {
        path: 'seguimiento',
        name: 'seguimiento',
        component: () => import('../views/sistema/SeguimientoView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'seguimiento',
          action: 'index'
        },
      },
      {
        path: 'siniestro',
        name: 'siniestro',
        component: () => import('../views/sistema/SiniestroView.vue'),
        meta: { 
          requireAuth: true,
          resource: 'siniestro',
          action: 'index'
        },
      },
      // {
      //   path: 'ficha/:mode/:id',
      //   name: 'FichaView',
      //   component: () => import('../views/sistema/FichaView.vue'),
      //   meta: { 
      //     requireAuth: true,
      //     resource: 'siniestro',
      //     action: 'index'
      //   },
      // },
      // {
      //   path: 'ficha/:mode/:id',
      //   name: 'FichaView',
      //   component: () => import('../views/sistema/FichaView.vue'),
      //   props: route => ({
      //       mode: route.params.mode,
      //       value: JSON.parse(route.params.value) // Parsea el objeto JSON almacenado en la URL
      //   }),
      //   meta: { 
      //       requireAuth: true,
      //       resource: 'siniestro',
      //       action: 'index'
      //   },
      // },
      {
        path: 'ficha/:mode',
        name: 'FichaView',
        component: () => import('../views/sistema/FichaView.vue'),
        props: route => ({
            mode: route.params.mode,
            //value: JSON.parse(route.params.value)
        }),
        meta: {
            requireAuth: true,
            resource: 'siniestro',
            action: 'index'
        },
      },
      {
        path: 'alerta/:mode',
        name: 'AlertaView',
        component: () => import('../views/sistema/AlertaView.vue'),
        props: route => ({
            mode: route.params.mode,
            value: JSON.parse(route.params.value)
        }),
        meta: { 
            requireAuth: true,
            resource: 'seguimiento',
            action: 'index'
        },
      },
      {
        path: 'alerta',
        name: 'Alerta',
        component: () => import('../views/sistema/AlertaView.vue'),
        meta: { 
            requireAuth: true,
            resource: 'seguimiento',
            action: 'index'
        },
      },
      

















          
      // {
      //   path: 'salarios',
      //   name: 'Salarios',
      //   component: SalariosView,
      //   meta: {requireAuth: true}
      // }
      // {
      //   path: 'empleado/:mode/:id',
      //   name: 'EmpleadoEditView',
      //   component: EmpleadoEditView,
      //   meta: {requireAuth: true}
      // },
    ]
  },
  {
    path: '/recuperar-password',
    name: 'RecuperarPassword',
    component: () => import('../views/auth/RecuperarPassword.vue'),
    meta: {
      resource: 'auth',
      action: 'show'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/auth/ResetPassword.vue'),
    meta: {
      resource: 'auth',
      action: 'show'
    }
  },
  {
    path: '/no-autorizado',
    name: 'NoAutorizado',
    component: () => import('../views/errors/NoAutorizado.vue'),
    meta: {
      resource: 'auth',
      action: 'show'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('../views/errors/NotFound.vue'),
  }
]




// const routes = [
//   {
//     path: '/',
//     name: 'home',
//     component: HomeView
//   },
//   {
//     path: '/about',
//     name: 'about',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
//   },
//   {
//     path: '/login',
//     name: 'login',
//     component: Login,
//     meta: {
//       redirectIfAuth: true,
//       //resource: 'auth',
//       //action: 'show'
//     }
//   },
//   {
//     path: '/admin',
//     name: 'admin',
//     component: AdminView,
//     meta: {
//             requireAuth: true
//           }
//     // path: '/admin',
//     // component: AppLayout,
//     // meta: { 
//     //   requireAuth: true,
//     //   resource: 'admin',
//     //   action: 'index'
//     // },
//     // children: [
//     //   {
//     //     path: '',
//     //     name: 'admin',
//     //     component: Admin,
//     //     meta: {
//     //       requireAuth: true,
//     //       resource: 'admin',
//     //       action: 'index'
//     //     },
//     //   },
//       // {
//       //   path: 'perfil',
//       //   name: 'perfil',
//       //   component: Perfil,
//       //   meta: {
//       //     requireAuth: true,
//       //     resource: 'perfil',
//       //     action: 'index'
//       //   },
//       // },
//       // {
//       //   path: 'usuario',
//       //   name: 'usuario',
//       //   component: () => import('../views/admin/usuario/Usuario.vue'),
//       //   meta: { 
//       //     requireAuth: true,
//       //     resource: 'user',
//       //     action: 'index'
//       //   },
//       // },
//       // {
//       //   path: 'role',
//       //   name: 'role',
//       //   component: () => import('../views/admin/role/Role.vue'),
//       //   meta: { 
//       //     requireAuth: true,
//       //     resource: 'role',
//       //     action: 'index'
//       //   },
//       // },
//       // {
//       //   path: 'role/:id',
//       //   name: 'roleEdit',
//       //   component: () => import('../views/admin/role/RoleEdit.vue'),
//       //   meta: { 
//       //     requireAuth: true,
//       //     resource: 'role',
//       //     action: 'update'
//       //   },
//       // },
//       // {
//       //   path: 'permiso',
//       //   name: 'Permiso',
//       //   component: () => import('../views/admin/role/Permiso.vue'),
//       //   meta: { 
//       //     requireAuth: true,
//       //     resource: 'permiso',
//       //     action: 'index'
//       //   },
//       // }
//     // ]
//   }
  
// ]

// const router = new VueRouter({
//   routes
// })
const router = new VueRouter({
  mode: 'history', // Habilita el modo de historial de HTML5
  base: process.env.BASE_URL,
  routes
})





router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token");

  // Aquí invoca la función canNavigate y almacena el resultado
  const hasPermission = canNavigate(to);

  console.log("to", to);
  console.log("canNavigate de index", hasPermission);

  if (!hasPermission) {
    console.log("NO TENGO PERMISOS");
    if (!token) {
      return next({ name: 'Login' });
    }
    // Si NO tengo permisos
    //return next({ name: 'NoAutorizado' });

    //this.$router.push({ path: '/no-autorizado' });

    window.location.href = "/no-autorizado"


  } else {
    console.log("Tiene canNavigate");

    if (to.meta.redirectIfAuth && token) {
      const userData = getUserData();
      console.log(userData.roles[0].nombre);
      return next(redireccionPorRol(userData ? userData.roles[0].nombre : null));
    }
    return next();
  }
});

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("token");

//   console.log("to");
//   console.log(to);
//   console.log("canNavigate de index");
//   console.log(canNavigate(to));  // Invoca la función canNavigate con 'to'

//   if (!canNavigate(to)) {
//     console.log("NO TENGO PERMISOS");
//     if (!token) {
//       return next({ name: 'Login' });
//     }
//     // si NO tengo permisos
//     return next({ name: 'NoAutorizado' });
//   } else {
//     console.log("tiene canNavigate");

//     if (to.meta.redirectIfAuth && token) {
//       const userData = getUserData();
//       console.log(userData.roles[0].nombre);
//       return next(redireccionPorRol(userData ? userData.roles[0].nombre : null));
//     }
//     return next();
//   }
// });


//  La anteultka

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("token")

//   console.log("to")
//   console.log(to)
//   console.log("canNavigate de index")
//   console.log(canNavigate)

//   if(!canNavigate(to)){
//     console.log("NO TENGO PERMISOS");
//     if(!token){
//       return next({name: 'Login'})
//     }
//     // si NO tengo permisos
//     return next({name: 'NoAutorizado'});
//   }else{

//     console.log("tiene canNavigate");

//     if(to.meta.redirectIfAuth && token){
//       const userData = getUserData();
//       console.log(userData.roles[0].nombre);
//       return next(redireccionPorRol(userData?userData.roles[0].nombre:null))
//     }
//     return next();


//   }


// });


//////////////////////////////////////////////////////

// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("token")

//   if(to.meta.requireAuth){

//     if(!token){
//       return next({name: "Login"});
//     }
//     return next();
//   }

//   if(to.meta.redirectIfAuth && token){
//     return next({name: "admin"});
//     //     const userData = getUserData();
//     //     console.log(userData.roles[0].nombre);
//     //     return next(redireccionPorRol(userData?userData.roles[0].nombre:null))
//     //   }
//   }

//   return next();

// });


// router.beforeEach((to, from, next) => {
//   let token = localStorage.getItem("token")

//   console.log(to)

//   if(!canNavigate(to)){
//     console.log("NO TENGO PERMISOS");
//     if(!token){
//       return next({name: 'Login'})
//     }
//     // si NO tengo permisos
//     return next({name: 'NoAutorizado'});
//   }

//   if(to.meta.redirectIfAuth && token){
//     const userData = getUserData();
//     console.log(userData.roles[0].nombre);
//     return next(redireccionPorRol(userData?userData.roles[0].nombre:null))
//   }
//   return next();

// });



export default router

