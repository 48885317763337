<template>
  <div>
    <v-container>
      <v-card justify="center">
        <v-card-text>
          <!-- <img src="https://logodownload.org/wp-content/uploads/2014/09/google-logo-1.png" alt="Image" height="50" class="mb-3" /> -->
            <img src="/login.jpg" alt="Image" height="50" class="mb-3" />
            <div class="text-900 text-3xl font-medium mb-3">
              Bienvenido!
            </div>
            <span class="text-600 font-medium">Ingresar</span>
          
          <div>
            <form @submit.prevent="funIngresar">
              <v-text-field type="text" id="email1" placeholder="Correo Electrónico" v-model="usuario.email"></v-text-field>
              <!-- <span>{{ errors.email }}</span> -->

              <v-text-field type="password" id="password1" v-model="usuario.password" placeholder="Password"></v-text-field>
              <!-- <span>{{ errors.password }}</span> -->

              <div>
                <a @click="recuperarPassword">¿Has olvidado tu contraseña?</a>
              </div>
              <div> 
              <v-btn type="submit"><span style="font-size:0.5rem;">Ingresar</span></v-btn>
              </div>
            </form>
          </div>
        
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
//import { useLayout } from '@/layout/composables/layout';
//import { ref, computed } from 'vue'
import authService from "./../../service/AuthService"
//import { ref } from 'vue'
//import { useRouter } from 'vue-router'
import ability from "../../casl/ability"
//import AppConfig from '@/layout/AppConfig.vue';
//import { getUserData, redireccionPorRol } from './../../casl/utils'

//const { layoutConfig } = useLayout();


//const logoUrl = computed(() => {
//    return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
//});

// const titulo = ref('Hola Mundo con Composition API 2');
//const checked = ref(false);
//const router = useRouter()
//const usuario = ref({email: "", password: ""});


// export default {
//     methods: {

//         async funIngresar(e) {
//             e.preventDefault();
//             errores.value = {}
//             try {
//                 const {data} = await authService.login(usuario.value);

//                 console.log(data);

//                 alert("Bienvenidosss...");


//                 /* data.user.permisos.push({action: 'show', subject: 'auth'})*/

//                 localStorage.setItem("token", data.access_token)
//                 localStorage.setItem("permisos", JSON.stringify(data.user.permisos))
//                 localStorage.setItem("userData", JSON.stringify(data.user))

//                 // controlar el tiempo de expiracion de token desde el front (cookies)
//                 // recuperar contraseña via correo
//                 // refresh token

//                 ability.update(data.user.permisos)

//                 // const userData = getUserData();

//                 alert("OK");
                
//                 //router.push(redireccionPorRol(data.user?data.user.roles[0].nombre:null))
                
//                 // Vue 2
//                 this.$router.push({ name: "admin" });
                

//                 // Vue 2
//                 //this.$router.push({ path: '/admin/home' });
                
//             } catch (error) {
//                 console.log("ERROR EN COMPONENTE LOGIN", error)
//                 if(error.response.data.errors){
//                     errores.value = error.response.data.errors
//                 }else{
//                     alert(error.response.data.message)
//                 }
//             }
//         }

//     },
//   };

// Vue 3:
/*
const funIngresar = async (e) => {
    e.preventDefault();
    errores.value = {}
    try {
        const {data} = await authService.login(usuario.value)

        console.log(data);

        data.user.permisos.push({action: 'show', subject: 'auth'})

        localStorage.setItem("token", data.access_token)
        localStorage.setItem("permisos", JSON.stringify(data.user.permisos))
        localStorage.setItem("userData", JSON.stringify(data.user))

// controlar el tiempo de expiracion de token desde el front (cookies)
// recuperar contraseña via correo
// refresh token

        ability.update(data.user.permisos)

        // const userData = getUserData();
        router.push(redireccionPorRol(data.user?data.user.roles[0].nombre:null))

        // alert("Bienvenido...")
        
    } catch (error) {
        console.log("ERROR EN COMPONENTE LOGIN", error)
        if(error.response.data.errors){
            errores.value = error.response.data.errors
        }else{
            alert(error.response.data.message)
        }
    }
}
*/

/*
const loginGoogle = async () => {
    const {data} = await authService.loginConGoogleURL();
    if(data.url){
        window.location.href = data.url;
    }
}
*/

// import { ref, computed } from 'vue';
// import authService from './../../service/AuthService';
// import { useRouter } from 'vue-router';
// import ability from './../../casl/ability';
// import { getUserData, redireccionPorRol } from './../../casl/utils';
// import AppConfig from '@/layout/AppConfig.vue';


// const usuario = ref({email: "admin@mail.com", password: "admin54321" });
// const errores = ref({})

export default {
  data() {
    return {
      checkbox: false,
      usuario: { email: "supervisor@mail.com", password: "admin4321" },
      //logoUrl: computed(() => `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`),
    };
  },
  methods: {


    recuperarPassword() {
        //router.push('/recuperar-password')
        this.$router.push("/recuperar-password")
        //this.$router.push({ name: "/recuperar-password" });
    },

    async funIngresar() {
      this.errores = {};
      try {
        const { data } = await authService.login(this.usuario);

        data.user.permisos.push({ action: 'show', subject: 'auth' });

        localStorage.setItem('token', data.access_token);
        localStorage.setItem('permisos', JSON.stringify(data.user.permisos));
        localStorage.setItem('userData', JSON.stringify(data.user));

        ability.update(data.user.permisos);

        //this.router.push(redireccionPorRol(data.user ? data.user.roles[0].nombre : null));

        this.$router.push({ name: "perfil" });


      } catch (error) {
        if (error.response.data.errors) {
          this.errores = error.response.data.errors;
        } else {
          alert(error.response.data.message);
        }
      }
    },
    async loginGoogle() {
      const { data } = await authService.loginConGoogleURL();
      if (data.url) {
        window.location.href = data.url;
      }
    },
  },
//   mounted() {
//     this.datos.token = this.$route.query.token;
//   },
//   components: {
//     AppConfig,
//   },
};
</script>

<style scoped>
.pi-eye {
  transform: scale(1.6);
  margin-right: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-right: 1rem;
}
</style>
