import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import { abilitiesPlugin } from '@casl/vue';
import ability from './casl/ability';

// Configuración de Vue Toastification
const toastOptions = {
  position: 'bottom-right',
  timeout: 3000,
  closeOnClick: true,
};

Vue.use(Toast, toastOptions);
//

Vue.use(abilitiesPlugin, ability, {
  useGlobalProperties: true
});


Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
