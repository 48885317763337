
import { Ability } from '@casl/ability'

const permisos = localStorage.getItem('permisos') ? JSON.parse(localStorage.getItem('permisos')) : null

export default new Ability(permisos || [{action: 'show', subject: 'auth'}])


// import { Ability } from '@casl/ability';

// const permisos = localStorage.getItem('permisos') ? JSON.parse(localStorage.getItem('permisos')) : null;

// const ability = new Ability(permisos || [{ action: 'show', subject: 'auth' }]);

// export default ability;



// import { Ability } from '@casl/ability';

// // Obtener permisos del localStorage
// const permisos = localStorage.getItem('permisos') ? JSON.parse(localStorage.getItem('permisos')) : null;

// // Crear una instancia de Ability
// const ability = new Ability(permisos || [{ action: 'show', subject: 'auth' }]);

// export default ability;

// // Agregar registros de depuración a la función can
// export const can = (action, subject) => {
//     const rule = findMatchingRule(action, subject);
//     console.log('Evaluating rule:', rule); // Agregar esta línea para depuración

//     // Resto de la lógica de evaluación de reglas

//     return result; // Asegúrate de que esto devuelva el resultado correcto
// }


/*
// En tu archivo ability.js
let result = false; // Define result antes de usarlo

import { Ability, detectSubjectType } from '@casl/ability';

// Obtener permisos del localStorage
const permisos = localStorage.getItem('permisos') ? JSON.parse(localStorage.getItem('permisos')) : null;

// Crear una instancia de Ability
const ability = new Ability(permisos || [{ action: 'show', subject: 'auth' }]);

export default ability;

// Agregar registros de depuración a la función can
export const can = (action, subject) => {
    const rule = findMatchingRule(action, subject);
    console.log('Evaluating rule:', rule); // Agregar esta línea para depuración

    // Resto de la lógica de evaluación de reglas

    
    return result; // Asegúrate de que esto devuelva el resultado correcto
}

// Definir la función findMatchingRule
function findMatchingRule(action, subject) {
    const subjectType = detectSubjectType(subject);
    const rules = ability.rulesFor(action, subjectType);

    return rules.find(rule => {
        if (typeof rule.subject === 'function') {
            return rule.subject(subject);
        }

        return rule.subject === subject;
    });
}

*/